<template>
    <div>
        <Header :seet="number" />
        <el-row type="flex" class="goodsclose">
            <el-col :span="3" style="width: 15.3%"></el-col>
            <el-col :span="17">
                <div class="closeBox">
                    <div class="closeBox_top">请填写并核对订单信息</div>
                    <div class="closeBox_infor">
                        <div class="flex-start">
                            <div class="infor_consignee">收货人信息</div>
                            <div class="infor_remind">
                                订单提交后收货地址不可修改，请您下单前再三核实
                            </div>
                        </div>
                        <div class="flex-between infor_box">
                            <div class="flex-start">
                                <div class="infor_name">{{ name }}</div>
                                <div class="infor_address">
                                    {{ address }} <span>{{ cellphone }}</span>
                                </div>
                                <div class="infor_btn" v-show="isDefault==1">默认地址</div>
                            </div>
                            <div
                                class="infor_new"
                                @click="dialogFormVisible = true"
                            >
                                新增收货地址
                            </div>
                        </div>
                        <div class="infor_select">
                            <div class="infor_text">
                                <div class="flex-start">
                                    <span class="orderAddressBtn" @click="isAddress()">选择其他地址V</span>
                                    <el-input v-model="addressInput" v-if="show" placeholder="通过收货人和地址搜索" size="mini" style="margin-left: 10px;width: 250px;">
                                        <el-button slot="append" icon="el-icon-search" @click="restaddress(addressInput)"></el-button>
                                    </el-input>
                                </div>
                                <div class="select-box" v-if="show" v-loading="showLoading">
                                    <div class="box-infor">
                                        <div
                                            class="infor-box"
                                            :class="{'bordercd9f49':addressId == item.id}"
                                            v-for="(item, index) in addressList"
                                            :key="index" @click.stop="inforBox(item)"

                                        >
                                            <div class="detail_title">
                                                {{ item.receiveName }}
                                                <span>{{
                                                    item.receivePhone
                                                }}</span>
                                            </div>
                                            <div class="detail_num">
                                                {{ item.province }}{{ item.city
                                                }}{{ item.area
                                                }}{{ item.address }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="closeBox_cent">
                        <div>
                            <div class="flex-start">
                                <div class="infor_consignee">发票信息</div>
                                <div class="infor_remind">
                                    发票信息会在集中开票时自动带入，集中开票时也可修改，请放心选择
                                </div>
                            </div>
                            <div class="flex-start cent_box">
                                <div class="cent_text">开票类型</div>
                                <div>增值税专用发票</div>
                            </div>
                            <div class="flex-start cent_box">
                                <div class="cent_text">开票方式</div>
                                <div>集中开票</div>
                            </div>
                            <div class="flex-start cent_box">
                                <div class="cent_text">发票内容</div>
                                <div>明细</div>
                            </div>
                            <div class="flex-start cent_box cent_infor">
                                <div>{{ invoiceHeader }} {{ taxpayerNo }}</div>
                                <div v-show="invoice==1"><div @click="goinvoice()" style="color:#cd9f49;margin:10px 0;">添加发票</div></div>
                            </div>
                            <div class="infor_select">
                                <div class="flex-start" style="margin-bottom: 5px;">
                                    <span style="cursor: pointer;" @click="onInvoice()">选择其他发票V</span>
                                    <el-input v-model="invoiceInput" v-if="showed" placeholder="按名称搜索发票信息" size="mini" style="margin-left: 10px;width: 200px;">
                                        <el-button slot="append" icon="el-icon-search" @click="getrestInvoice(invoiceInput)"></el-button>
                                    </el-input>
                                </div>
                                <div class="" v-if="showed">
                                    <div class="infor_select_cent">
                                        <el-table
                                            :data="tabledata"
                                            border
                                            :header-cell-style="{
                                                background: '#cccccc',
                                                color: '#333',
                                                textAlign: 'center',
                                            }"
                                            :cell-style="{
                                                textAlign: 'center',
                                            }"
                                            style="
                                                width: 100%;
                                                text-align: center;
                                            "
                                            @current-change="
                                                handleSelectionChange
                                            "
                                            highlight-current-row
                                        >
                                            <el-table-column label="选择" width="70" center >
                                                <template slot-scope="scope">
                                                    <div @click.stop.prevent="getCurrentRow(scope.$index,scope.row)">
                                                        <el-radio class="radio" v-model="radio" :label="scope.$index">&nbsp;</el-radio>
                                                    </div>
                                                    
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                prop="invoiceHeader"
                                                label="名称"
                                                width="280"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="taxpayerNo"
                                                label="纳税人识别号"
                                                width="250"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="registeredAddress"
                                                label="地址电话"
                                                width="450"
                                            >
                                                <template slot-scope="scope">
                                                    <div>{{scope.row.registeredAddress}}</div>
                                                    <div>{{scope.row.registeredPhone}}</div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                prop="bankAccount"
                                                label="开户行及账号"
                                                width="250"
                                            >
                                                <template slot-scope="scope">
                                                    <div>{{scope.row.bankName}}</div>
                                                    <div>{{scope.row.bankAccount}}</div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top:30px">
                            <div class="flex-start">
                                <div class="infor_consignee">下单项目</div>
                                <div class="infor_remind">
                                    订单提交后下单项目不可修改，请您下单前再三核实
                                </div>
                            </div>
                            <div class="flex-start cent_box" style="margin-top:20px">
                                <div class="cent_text">订单归属</div>
                                <div>
                                    <el-radio-group v-model="radios" @change="toggleTab()">
                                        <el-radio :label="item.value" :key="item.value" v-for="item in radio1" >{{item.label}}</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                            <div class="flex-start cent_box" style="margin-top:20px">
                                <div class="cent_text">下单组织</div>
                                <div>
                                   <el-select v-model="place" placeholder="请选择">
                                        <el-option
                                        v-for="item in placeOptions"
                                        :key="item.manageId"
                                        :label="item.manageName"
                                        :value="item.manageId">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div v-if="!$store.state.externalId" class="flex-start cent_box" style="margin-top:20px">
                                <div class="cent_text" style="margin-right: 22px;">发票收件邮箱</div>
                                <div>
                                    <el-autocomplete
                                    class="inline-input"
                                    v-model="email"
                                    clearable
                                    :fetch-suggestions="filterMethod"
                                    placeholder="请输入邮箱地址"
                                    @select="handleEmailSelect"
                                    ></el-autocomplete>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top:30px">
                            <div class="flex-start">
                                <div class="infor_consignee">审批文件</div>
                                <div class="infor_remind">
                                    最多上传10个文件;文件格式仅支持PDF、JPG、PNG,文件大小需≤10MB。
                                </div>
                            </div>
                            <div class="cent_box" style="margin-top:20px">
                                <el-upload
                                    v-if="!fileLoading"
                                    class="upload-file"
                                    ref="approveUpload"
                                    action=""
                                    accept=".jpg, .png, .pdf"
                                    :multiple="true"
                                    :auto-upload="true"
                                    :show-file-list="false"
                                    :before-upload="beforeApproveFile"
                                    :http-request="requestApproveFile"
                                >
                                        <el-button size="small" type="warning">上传文件</el-button>
                                </el-upload>
                                <el-button v-else size="small" type="warning" :loading="true" :disabled="true">上传文件中...</el-button>
                                <div id="dialogVisibleApproveBox">
                                    <div class="approveFileList" v-if="approveFileList.length > 0">
                                        <div class="fileList flex-between" v-for="(item,index) in approveFileList" :key="index">
                                            <div style="width: 68%;">审批文件{{index+1}}：{{item.fileName}}</div>
                                            <div class="fileBtn" style="text-align: right;">
                                                <span @click="approvePreview(item)">在线预览</span>
                                                <el-popconfirm
                                                    title="确定删除吗？"
                                                    @confirm="approveDel(item,index)"
                                                >
                                                    <span slot="reference">删除</span>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cent_goods">
                            <div class="goods_text flex-between">
                                <div>商品清单</div>
                                <div class="goods_list" v-show="kind==3||kind==5">
                                    购卡明细：
                                    <span v-show="uploadgo==false">未上传</span>
                                    <span v-show="uploadgo==true">已上传</span>
                                    <span class="goods_listBtn" @click="uploadflie = true">点击上传</span>
                                    <!-- 'http://yjlifemall.iis.com.cn/FgM3i6cvYAeI0QNKNwBPENGSr20R' -->
                                    <span class="goods_listBtn"
                                        :class="{
                                            notAllowed: exportLoading
                                        }" 
                                        @click="exportTemplate(queryCartModelUrl, '购卡明细清单模板.xlsx')"
                                    >
                                        <i v-if="exportLoading" class="el-icon-loading"></i>
                                        导出模板
                                    </span>
                                </div>
                            </div>
                            <!-- http://yjlifemall.iis.com.cn/FrW5mua-FwCEyk9Do0uF9pPZ9IEP -->
                            <el-table
                                :data="tableData"
                                v-loading="tableLoading"
                                border
                                :header-cell-style="{
                                    background: '#cccccc',
                                    color: '#333',
                                    textAlign: 'center',
                                }"
                                :cell-style="{ textAlign: 'center' }"
                                style="width: 100%; text-align: center"
                            >
                                <el-table-column
                                    prop="infor"
                                    label="商品信息"
                                    width="551"
                                >
                                    <template slot-scope="{ row }">
                                        <div v-if="Array.isArray(row.infor)">
                                            <div
                                                v-for="(
                                                    adr, index
                                                ) in row.infor"
                                                :key="index"
                                                class="rowBox flex-center"
                                            >
                                                <img :src="adr.img" alt="" />
                                                <p>{{ adr.name }}</p>
                                            </div>
                                        </div>
                                        <span v-else>{{ row.infor }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="size"
                                    label="规格"
                                    width="161"
                                >
                                    <template slot-scope="{ row }">
                                        <div v-if="Array.isArray(row.size)">
                                            <p
                                                v-for="(adr, index) in row.size"
                                                :key="index"
                                            >
                                                {{ adr }}
                                            </p>
                                        </div>
                                        <span v-else>{{ row.size }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="unitprice"
                                    label="单价"
                                    width="211"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="number"
                                    label="数量"
                                    width="179"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="price"
                                    label="小计"
                                    width="178"
                                >
                                </el-table-column>
                            </el-table>
                        </div>
                        <!-- <div class="cent_goods" v-show="kind==3">
                            <div class="goods_text">
                                <div>审核文件</div>
                                <div class="boxxlse">
                                    <el-upload
                                        class="upload-demo"
                                        action="/api/orders/order/uploadFiles"
                                        :on-preview="handlePreview"
                                        :on-remove="handleRemove"
                                        :before-remove="beforeRemove"
                                        :on-success="handleSuccess"
                                        multiple
                                        :limit="6"
                                        :headers="headers"
                                        :file-list="fileListed">
                                        <el-button size="small">点击上传</el-button>
                                        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                                    </el-upload>
                                </div> -->
                                
                                <!-- <span class="goods_listBtn">点击上传</span>
                                <div class="el-upload__tip">最多上传6个文件；文件格式支持docx、xlsx、pdf、jpg、png，文件大小需≤10MB</div> -->
                            <!-- </div> -->
                            
                        <!-- </div> -->
                        <div class="cent_purchase">
                            <div class="goods_text">采购信息</div>
                            <div class="flex-start purchase">
                                <div>订单备注</div>
                                <div class="purchase_text">
                                    订单备注信息仅作为您自己浏览时的备注信息，并不会向商家.快递人员展示
                                </div>
                            </div>
                            <div>
                                <el-input
                                    type="textarea"
                                    v-model="textarea"
                                    placeholder="对本次采购进行备注，以便于之后查看或导出。请勿填写有关支付、收货、发票方面信息"
                                    :rows="3"
                                    maxlength="200"
                                    show-word-limit
                                ></el-input>
                            </div>
                        </div>
                        <div class="flex-end">
                            <div class="cent_how">
                                <div>
                                    共{{ goodsTypeNums }}件商品，订单总金额：¥{{
                                        payAmount
                                    }}
                                </div>
                                <div class="flex-end freight">
                                    运费：¥{{ freight }}
                                </div>
                            </div>
                        </div>
                        <div class="cent-remind">
                            <div class="remind_text">订单注意事项</div>
                            <div>
                                订单提交后将进行审核，商品预计送达日期为无需审批的最快时效，实际配送将在订单审批和支付完成后执行。
                            </div>
                            <div>
                                订单中存在不支持7天无理由退货商品，请确认相关商品信息后提交订单。
                            </div>
                            <div>订单中存在京东慧采商品，请在7天内完成支付，否则系统将自动取消订单。</div>
                        </div>
                        <div class="flex-end">
                            <div class="cent_inall">
                                <div class="flex-end">
                                    应付总额：<span>¥{{ totalAmount }}</span>
                                </div>
                                <div>
                                    寄送至：{{ address }} 收货人：{{ name }}
                                    {{ cellphone }}
                                </div>
                                <div class="flex-end">
                                    <!-- <div class="inall_btn" @click="throttle(null, 800)"> -->
                                    <div class="inall_btn" @click="secondaryConfirmation()">
                                        提交订单
                                        <!-- <i class="el-icon-loading inall_btn_icon" v-if="inallBtnIcon"></i> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 新增地址 -->
                    <el-dialog
                        title="新增收货人信息"
                        :visible.sync="dialogFormVisible"
                        width="40%"
                        center
                        :close-on-click-modal="false"
                    >
                        <el-form ref="dataForm" label-width="100px" :model="dialogForm" :rules="dialogFormRules">
                            <el-form-item prop="userName" label="收货人">
                                <el-input
                                    v-model="dialogForm.userName"
                                    placeholder="请输入收货人姓名"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="number" label="手机号码">
                                <el-input
                                    v-model="dialogForm.number"
                                    placeholder="请填写收货人的手机号"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="selectedOptions" label="所在地址">
                                <el-cascader
                                    :options="options"
                                    ref="cascaderAddr"
                                    v-model="dialogForm.selectedOptions"
                                    @change="handleChange"
                                >
                                </el-cascader>
                            </el-form-item>
                            <el-form-item prop="company" label="详细地址">
                                <el-input
                                    v-model="dialogForm.company"
                                    placeholder="请输入详细收货地址信息"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="demand" label="固定电话">
                                <el-input
                                    v-model="dialogForm.demand"
                                    placeholder="选填，面单随机打印"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="demand" label="邮箱">
                                <el-input
                                    v-model="dialogForm.demands"
                                    placeholder="选填"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="设置默认">
                                <el-switch
                                    v-model="dialogForm.delivery"
                                ></el-switch>
                            </el-form-item>
                        </el-form>
                        <span slot="footer" class="dialog-footer">
                            <el-button size="small" type="warning" @click="giveAddress()">确 定</el-button>
                            <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
                        </span>
                    </el-dialog>
                    <!-- 报错 -->
                    <el-dialog title="不可购买商品" :visible.sync="dialogTableVisible">
                        <el-table :data="gridData" border :height="gridData.length>5 ? 312 :''">
                            <el-table-column property="skuId" label="skuId" width="100"></el-table-column>
                            <el-table-column property="notBuyGoodsName" label="商品名称"></el-table-column>
                            <el-table-column property="notBuyType" label="不可以购买原因" width="250"></el-table-column>
                        </el-table>
                    </el-dialog>
                    <!-- 点击上传购物卡明细 -->
                    <el-dialog title="上传购物卡明细" width="40%" :visible.sync="uploadflie" :modal-append-to-body="false" class="tbdialog undraggablebox">
                        <div style="margin-left: 50px;">
                            <span>上传数据：</span>
                            <el-upload style="display: inline"
                                        class="upload-demo"
                                        action="#"
                                        accept=".xls, .xlsx"
                                        :file-list="fileList"
                                        :before-upload="beforeUpload"
                                        :on-remove="handleRemove"
                                        :on-change="getValChange"
                                        :http-request="handlePictureSuccess">
                                <el-button type="text">选择文件</el-button>
                            </el-upload>
                        </div>
                        <span slot="footer" class="dialog-footer">
                        <el-button type="primary" :disabled="uploadfile===undefined" :loading="importBtn" @click="handleUpload">确 定</el-button>
                        </span>
                    </el-dialog>
                    <!-- 报错 -->
                    <el-dialog title="不可购买商品" :visible.sync="dialogTable">
                        <el-table :data="notgoodsData" border :height="notgoodsData.length>5 ? 312 :''"> 
                            <el-table-column property="notBuyGoodsName" label="商品名称"></el-table-column>
                            <el-table-column property="notBuyType" label="不可以购买原因" width="250"></el-table-column>
                        </el-table>
                    </el-dialog>
                    <el-dialog
                        custom-class="custom"
                        :visible.sync="oderTishi"
                        width="20%"
                        :close-on-click-modal="false"
                        :show-close="false">
                        <span>该订单正在提交中，请耐心等待</span>
                    </el-dialog>
                </div>
            </el-col>
            <el-col :span="3" style="width: 15.5%"></el-col>
        </el-row>
        <el-image-viewer
            v-if="showViewer"
            :on-close="closeShowViewer"
            :url-list="showViewerList"
            style="z-index: 3000"
        />
        <previewPDF
            v-if="showPDF"
            ref="previewPDF"
            :url="srcPDF"
            :title="titlePDF"
            @closeShowPDF="closeShowPDF"
        ></previewPDF>
        <Footer />
        <div class="secondaryConfirma">
            <el-dialog
                title="确认信息"
                :visible.sync="secondaryConfirmationDialogVisible"
                :destroy-on-close="true"
                width="500px"
                top="10vh"
                center
            >
                <div v-loading="secondaryLoading" class="secondary-content">
                    <div class="secondary-title">开票信息</div>
                    <div class="secondary-text">
                        <span>发票抬头：</span>{{invoiceHeader}}
                    </div>
                    <div class="secondary-text">
                        <span>纳税人识别码：</span>{{taxpayerNo}}
                    </div>
                    <div class="secondary-title">收件人信息</div>
                    <div class="secondary-text">
                        <span>收件人：</span>{{name}}
                    </div>
                    <div class="secondary-text">
                        <span>手机号码：</span>{{cellphone}}
                    </div>
                    <div class="secondary-text">
                        <span>收货地址：</span>{{address}}
                    </div>
                    <div class="secondary-title">下单项目信息</div>
                    <div class="secondary-text">
                        <span>项目名称：</span>{{placeName}}
                    </div>
                    <div class="secondary-button">
                        <el-button
                            type="primary"
                            size="small"
                            @click="throttle(null, 800)"
                        >确定</el-button
                        >
                        <el-button
                            size="small"
                            @click="secondaryConfirmationDialogVisible = false"
                        >取消</el-button
                        >
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Header from "@/components/Layout/header.vue";
import Footer from "@/components/Layout/footer.vue";
import {
    orderInfro,
    getAddress,
    restaddress,
    newlyAdd,
    getInvoice,
    calculateOrder,
    importWelfareCard,
    getOrderAscription,
    queryCartModel,
    getrestInvoice,
    submitOrder,
    // uploadFiles
    uploadApproveFile,
    getInvoiceEmail
} from "@/api/order/order";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import previewPDF from '@/components/public/previewPDF.vue'
import { phoneReg } from '@/utils/regular'

let pcas = require("@/assets/json/pca-code.json");
export default {
    components: { Header, Footer, ElImageViewer, previewPDF },
    data() {
        return {
            exportLoading: false,
            number: "3",
            num: "",
            goodsId: "",
            skuId: "",
            tableData: [],
            tableLoading: true,
            options: pcas,
            dialogFormVisible: false,
            secondaryLoading: false,
            secondaryConfirmationDialogVisible: false,
            dialogForm: {
                userName: "",
                number: "",
                company: "",
                delivery: false,
                demand: "",
                demands:'',
                selectedOptions: [],
            },
            dialogFormRules: {
                userName: [
                    { required: true, message: '请填写收货人', trigger: 'blur' }
                ],
                number: [
                    { required: true, message: '请填写手机号码', trigger: 'blur' },
                    { pattern: phoneReg, message: "请输入正确的手机号码"}
                ],
                company: [
                    { required: true, message: '请填写详细地址', trigger: 'blur' }
                ],
                selectedOptions: [
                    { required: true, message: '请选择所在地址', trigger: 'change' }
                ],
            },
            name: "",
            cellphone: "",
            address: "",
            radio: '',
            addressList: [],
            show: false,
            showLoading: false,
            showed: false,
            addressId: '',
            freight: "",
            payAmount: "",
            totalAmount: "",
            goodsTypeNums: "",
            tabledata: [],
            multipleSelection: [],
            taxpayerNo: "",
            invoiceHeader: "",
            textarea: "",
            invoiceRecordId: "",
            isDefault:'',
            invoice:0,
            goodsInfor:[],
            prev: 0,
            dialogTableVisible:false,
            gridData:[],
            uploadflie:false,
            //上传的文件
            uploadfile: undefined,
            importBtn:false,
            dialogTable:false,
            notgoodsData:[],
            fileList:[],
            cardBatchObj: {
                cardBatchFileName: '',
                cardBatchFileUrl: '',
                cardBatchNo: ''
            },
            FilesList:[],
            //上传的文件
            uploadfileed: undefined,
            importBtned:false,
            uploadflieed:false,
            fileListed:[],
            kind:'',
            headers: {Authorization: localStorage.getItem('token') },
            oderTishi:false,
            uploadgo:false,
            radios:'1',
            radio1:[{value:"1",label:'为公司下单'},{value:"2",label:'为项目下单'}],
            placeOptions: [],
            place: '',
            email: '',
            emailList: [],
            emailLoading: false,
            queryCartModelUrl: '',//获取购卡模板地址
            // inallBtnIcon: false,
            // 审批文件上传
            approveFileList: [],
            upApproveFileList: [],
            showViewer: false,
            showViewerList: [],// 图片预览
            fileLoading: false,
            showPDF: false,
            srcPDF: '',// pdf预览
            titlePDF: '',
            addressInput: '', // 地址搜索字段
            invoiceInput: '' // 发票字段
        };
    },
    computed:{
        MultipleSelection: {
          get () { return this.$store.state.common.MultipleSelection },
          set (val) { this.$store.commit('common/upmultipleSelection', val) }
        },
        GoodsInfor: {
          get () { return this.$store.state.common.GoodsInfor },
          set (val) { this.$store.commit('common/upgoodsInfor', val) }
        },
        // 显示项目名称
        placeName: {
            get () {
                let arr = this.placeOptions.filter(item => item.manageId == this.place)
                let str = ""
                if (arr.length == 1) str = arr[0].manageName
                return str
            }
        }

    },
    created() {
        this.getQueryCartModel();//获取购卡模板地址
        this.uploadfile=undefined
        console.log('this.$route.query', this.$route.query)
        if(JSON.stringify(this.$route.query)!='{}'){
            console.log(this.$route.query, '')
            this.goodsId = this.$route.query.goodsid;
            this.num = this.$route.query.number;
            this.skuId = this.$route.query.skuid;
            if(this.$route.query.kind==3){
                this.kind=3
            }else if(this.$route.query.kind==5){
                this.kind=5
            }
        }else if(this.GoodsInfor.length!=0){
            console.log('666', '')
            this.goodsInfor = this.GoodsInfor
                ? JSON.parse(this.GoodsInfor)
                : [];
            console.log(this.goodsInfor)
            this.goodsInfor.map(item=>{
                if(item.kind==3){
                    this.kind=3
                }else if(item.kind==5){
                    this.kind=5
                }
            })
        }else if(this.MultipleSelection.length!=0){
            console.log('555', '')
            this.goodsArr = this.MultipleSelection
                ? JSON.parse(this.MultipleSelection)
                : [];
            this.goodsArr.forEach((item) => {
                item.nums = Number(item.nums);
                item.productSkuStockId = Number(item.productSkuStockId);
            });
            console.log(this.goodsArr)
            this.goodsArr.map(item=>{
                if(item.kind==3){
                    this.kind=3
                }else if(item.kind==5){
                    this.kind=5
                }
            })
        }
        // console.log(this.goodsArr, "this.goodsArr");
        // let goodsInfor= JSON.parse(this.$route.query.goodsInfor)
        // if(this.MultipleSelection.length!=0){
        //     this.goodsInfor=this.GoodsInfor
        //     ? JSON.parse(this.GoodsInfor)
        //     : [];
        // }else{
        //     // data = JSON.parse(sessionStorage.getItem('name'))
        //     this.goodsInfor=sessionStorage.getItem('goodsInfor')
        //     ? JSON.parse(sessionStorage.getItem('goodsInfor'))
        //     : [];
        // }
        
        // console.log(this.goodsInfor, "this.goodsInfor");

        this.allText()
        this.getOrderAscription(this.radios)
        if(!this.$store.state.externalId) {
            this.getEmail()
        }
    },
    
    methods: {
        getEmail() {
            this.emailLoading = true;
            getInvoiceEmail({}).then(res => {
                if(res.code == '00000') {
                    this.emailList = res.data.map(item => ({
                        label: item,
                        value: item
                    }))
                }
            }).finally(() => this.emailLoading = false)
        },
        // 邮箱搜索
        filterMethod(query, cb) {
            if (query !== '') {
                let data = this.emailList.filter(item => {
                    return item.value.indexOf(query) > -1;
                });
                cb(data)
            } else {
                cb(this.emailList)
            }
        },
        handleEmailSelect(item){
            this.email = item.value
        },
        // 二次确认弹窗
        secondaryConfirmation() {
            if(this.addressId==''){
                this.$message.error('地址没有选择');
            }else if(this.invoiceRecordId==''){
                this.$message.error('发票没有选择');
            }else if(this.ascriptionId==''){
                this.$message.error('请选择为公司或者为项目下单');
            }else if(this.place==''){
                this.$message.error('请选择订单归属的下单组织');
            }else if(this.fileLoading){
                this.$message({
                    message: '有审批文件正在上传！请稍等。',
                    type: 'warning'
                });
            }else if(!this.$store.state.externalId && !this.email){
                this.$message.error('请输入邮箱地址');
            }else {
                this.secondaryConfirmationDialogVisible = true
            }
        },
        //获取购卡模板地址
        getQueryCartModel(){
            queryCartModel(1).then(res => {
                console.log(res);
                this.queryCartModelUrl = res.data
            })
        },
        getinfor() {},
        //获取下单清单接口
        getplace() {
            this.tableLoading = true
            let data = {
                skuInfoVOList: [
                    {
                        goodsId: this.goodsId,
                        num: this.num,
                        skuId: this.skuId,
                    },
                ],
            };
            if (this.goodsArr && this.goodsArr.length != 0) {
                let arr = []
                this.goodsArr.forEach((item) => {
                    arr.push({
                        shopId:item.id,
                        goodsId: item.goodsId,
                        num: item.nums,
                        skuId: item.productSkuStockId,
                    });
                });
                data.skuInfoVOList = arr
            }
            if (this.goodsInfor && this.goodsInfor.length != 0) {
                let arr = []
                this.goodsInfor.forEach((item) => {
                    arr.push({
                        goodsId: item.goodsId,
                        num: item.nums,
                        skuId: item.skuId,
                    });
                });
                data.skuInfoVOList = arr
            }
            orderInfro(data).then((data) => {
                this.tableLoading = false
                if (data && data.code === "00000") {
                    data.data.forEach((item) => {
                        console.log(data, "datadata");
                        let obj = {};
                        let objs = {};
                        let infor = [];
                        objs.img = item.goodsPic;
                        objs.name = item.goodsName;
                        infor.push(objs);
                        obj.infor = infor;
                        obj.size = item.skuValue;
                        obj.unitprice = item.goodsPrice;
                        obj.number = item.nums;
                        obj.price = (item.allGoodsPrice).toFixed(2);
                        this.tableData.push(obj);
                    });
                }
            }).catch(() => {this.tableLoading = false});
        },
        //获取地址
        getAddress() {
            let data = {
                addressInfo: "",
                id: "",
                labelName: "",
            };
            getAddress(data).then((data) => {
                if (data && data.code === "00000") {
                    if(data.data==null){
                        this.dialogFormVisible=true
                    }else{
                        this.name = data.data.receiveName;
                        this.cellphone = data.data.receivePhone;
                        if (data.data.address != null) {
                            this.address =
                                data.data.province +
                                data.data.city +
                                data.data.area +
                                data.data.address;
                        } else {
                            this.address =
                                data.data.province +
                                data.data.city +
                                data.data.area;
                        }
                        this.addressId = data.data.id;
                        this.isDefault=data.data.isDefault
                        console.log(this.addressId)
                    }
                    
                }
            });
        },
        //是否显示选择其他地址
        isAddress() {
            this.show = !this.show;
            if (this.show) {
                this.restaddress('')
            } else {
                this.addressInput = ''
            }
        },
        //查看其它地址
        restaddress(addressInput) {
            this.showLoading = true
            let data = {
                addressInfo: addressInput,
                id: "",
                labelName: ""
            };
            restaddress(data).then((data) => {
                this.showLoading = false
                if (data && data.code === "00000") {
                    if(data.data==null){
                        this.dialogFormVisible=true
                    }else{
                        this.addressList = data.data;
                    }
                    
                }
            }).catch( () => {this.showLoading = false})
        },
        inforBox(item){
            console.log(item)
            this.name = item.receiveName;
            this.cellphone = item.receivePhone;
             this.addressId = item.id;
             this.isDefault =item.isDefault
             if (item.address != null) {
                this.address =
                    item.province +
                    item.city +
                    item.area +
                    item.address;
            } else {
                this.address =
                    item.province +
                    item.city +
                    item.area
            }
            this.calculateOrder();
        },
        //新增地址
        giveAddress() {
            this.$refs['dataForm'].validate( (valid) => {
                if(valid) {
                    let thsAreaCode=this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels
                    let data = {
                        address: this.dialogForm.company,
                        area: thsAreaCode[2],
                        city: thsAreaCode[1],
                        isDefault: this.dialogForm.delivery ? 1 : 0,
                        labelName: "",
                        province: thsAreaCode[0],
                        receiveName: this.dialogForm.userName,
                        receivePhone: this.dialogForm.number,
                    };
                    // console.log(data);
                    newlyAdd(data).then((data) => {
                        if (data && data.code === "00000") {
                            this.dialogFormVisible = false;
                            this.name = data.data.receiveName;
                            this.cellphone = data.data.receivePhone;
                            this.addressId = data.data.id;
                            if (data.data.address != null) {
                                this.address =
                                    data.data.province +
                                    data.data.city +
                                    data.data.area +
                                    data.data.address;
                            } else {
                                this.address =
                                    data.data.province +
                                    data.data.city +
                                    data.data.area;
                            }
                            console.log(this.addressId)
                            this.calculateOrder()
                        }
                    });
                }
            })
            
        },
        //获取发票
        getInvoice() {
            let data = {
                id: "",
                name: "",
            };
            getInvoice(data).then((data) => {
                if (data && data.code == "00000") {
                    if(data.data==null){
                        this.invoice=1
                    }else{
                        this.invoiceHeader = data.data.invoiceHeader;
                        this.taxpayerNo = data.data.taxpayerNo;
                        this.invoiceRecordId = data.data.id;
                    }
                    
                }
            });
        },
        // 是否显示其他发票
        onInvoice() {
            this.showed = !this.showed;
            if (this.showed) {
                this.getrestInvoice('')
            } else {
                this.invoiceInput = ''
            }
        },
        //获取其它发票
        getrestInvoice(invoiceInput) {
            let data = {
                id: "",
                name: invoiceInput,
            };
            getrestInvoice(data).then((data) => {
                if (data && data.code == "00000") {
                    console.log(this.invoiceRecordId)
                    this.tabledata = data.data;
                    this.tabledata.forEach((item,index)=>{
                        if(item.id ===this.invoiceRecordId){
                            this.radio=index
                        }
                    })
                    
                }
            });
        },
        getCurrentRow(index,val){
            console.log(index,val)
            this.radio=index
            this.invoiceHeader = val.invoiceHeader;
            this.taxpayerNo = val.taxpayerNo;
            this.invoiceRecordId = val.id;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.invoiceHeader =val.invoiceHeader;
            this.taxpayerNo = val.taxpayerNo;
            this.invoiceRecordId = val.id;
        },
        //计算订单价格
        calculateOrder() {
            let data = {
                addressId: this.addressId,
                skuInfoVOList: [
                    {
                        goodsId: this.goodsId,
                        num: this.num,
                        skuId: this.skuId,
                    },
                ],
            };
            console.log(data)
            if (this.goodsArr && this.goodsArr.length != 0) {
                let arr = []
                this.goodsArr.forEach((item) => {
                    arr.push({
                        shopId:item.id,
                        goodsId: item.goodsId,
                        num: item.nums,
                        skuId: item.productSkuStockId,
                    });
                });
                data.skuInfoVOList = arr
            }
            if (this.goodsInfor && this.goodsInfor.length != 0) {
                let arr = []
                this.goodsInfor.forEach((item) => {
                    arr.push({
                        goodsId: item.goodsId,
                        num: item.nums,
                        skuId: item.skuId,
                    });
                });
                data.skuInfoVOList = arr
            }
            calculateOrder(data).then((data) => {
                if (data && data.code === "00000") {
                    console.log(data);
                    if (data.data.isContinueBuy == true) {
                        this.payAmount = (data.data.payAmount).toFixed(2);
                        this.freight = data.data.freight;
                        this.totalAmount = (data.data.totalAmount).toFixed(2);
                        this.goodsTypeNums = data.data.goodsTypeNums;
                        // console.log(this.payAmount,999)
                    }else{
                        if(data.data.notContinueBuyType==2){
                            this.$notify.error({
                                title: '错误',
                                message: "禹佳卡商品和普通商品不可混合购买",
                            });
                        }else if(data.data.notContinueBuyType==3){
                            this.$notify.error({
                                title: '错误',
                                message: "福利商品和普通商品不能一起购买",
                            });
                        }else if(data.data.notContinueBuyType==4){
                            this.$notify.error({
                                title: '错误',
                                message: "禹佳卡商品和福利商品不能一起购买",
                            });
                        }
                        let notBuyType=''
                        if(data.data.notBuyGoodsDTOList.length>1){
                            this.dialogTableVisible=true
                            this.gridData=data.data.notBuyGoodsDTOList
                            this.gridData.forEach((item)=>{
                                if(item.notBuyType==1){
                                    item.notBuyType='商品下架'
                                }else if(item.notBuyType==2){
                                    item.notBuyType='商品库存不足 '
                                }else if(item.notBuyType==3){
                                    item.notBuyType='商品价格异常 '
                                }else if(item.notBuyType==4){
                                    item.notBuyType='商品配送地区限制 '
                                }else if(item.notBuyType==5){
                                    item.notBuyType='商品超过限购数量 '
                                }else if(item.notBuyType==6){
                                    item.notBuyType='福利卡商品与普通商品无法一起购买 '
                                }else if(item.notBuyType==19){
                                    item.notBuyType='怡亚通商品不可售 '
                                }else if(item.notBuyType==21){
                                    item.notBuyType='地址怡亚通无法识别 '
                                }

                            })
                        }else{
                            data.data.notBuyGoodsDTOList.forEach((item)=>{
                                if(item.notBuyType==1){
                                    notBuyType='商品下架'
                                }else if(item.notBuyType==2){
                                    notBuyType='商品库存不足 '
                                }else if(item.notBuyType==3){
                                    notBuyType='商品价格异常 '
                                }else if(item.notBuyType==4){
                                    notBuyType='商品配送地区限制 '
                                }else if(item.notBuyType==5){
                                    notBuyType='商品超过限购数量 '
                                }else if(item.notBuyType==6){
                                    notBuyType='福利卡商品与普通商品无法一起购买 '
                                }else if(item.notBuyType==19){
                                    notBuyType='怡亚通商品不可售 '
                                }else if(item.notBuyType==21){
                                    notBuyType='地址怡亚通无法识别 '
                                }
                                this.$notify.error({
                                    title: '错误',
                                    message: "商品名称:"+item.notBuyGoodsName,
                                });
                                this.$nextTick(() => {
                                    this.$notify.error({
                                        title: '错误',
                                        message:"不可购买原因:"+notBuyType,
                                    });
                                })
                            })
                        }
                        
                    }
                }
            });
            
        },
        //地址选择
        handleChange(e, form, thsAreaCode) {
            thsAreaCode = this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels// 注意2： 获取label值
            console.log(thsAreaCode) // 注意3： 最终结果是个一维数组对
            // console.log(thsAreaCode[0] + thsAreaCode[1] + thsAreaCode[2])

        },
        throttle(fn, delay) {
            var now = Date.now();
            console.log(now - this.prev,'now - this.prev');
            if (now - this.prev > delay) {
                this.inallBtn();
                this.prev = Date.now();
            }
        },
        //提交订单
        inallBtn() {
            if(this.addressId==''){
                this.$message.error('地址没有选择');
            }else if(this.invoiceRecordId==''){
                this.$message.error('发票没有选择');
            }else if(this.ascriptionId==''){
                this.$message.error('请选择为公司或者为项目下单');
            }else if(this.fileLoading){
                this.$message({
                    message: '有审批文件正在上传！请稍等。',
                    type: 'warning'
                });
            }else if(!this.secondaryLoading){
                // this.inallBtnIcon = true
                this.secondaryLoading = true
                
                let data = {
                    addressId: parseInt(this.addressId),
                    invoiceRecordId: parseInt(this.invoiceRecordId),
                    ascriptionId: this.place,
                    ascriptionType: this.radios,
                    receivingType: 0,
                    remark:this.textarea,
                    ...this.cardBatchObj,
                    skuInfoVOList: [
                        {
                            actType: 0,
                            goodsId: parseInt(this.goodsId),
                            num: parseInt(this.num),
                            skuId: parseInt(this.skuId),
                        },
                    ],
                };
                if(!this.$store.state.externalId){
                    data.invoiceEmail = this.email
                }
                if (this.goodsArr && this.goodsArr.length != 0) {
                    let arr = []
                    this.goodsArr.forEach((item) => {
                        arr.push({
                            shopId:item.id,
                            goodsId: item.goodsId,
                            num: item.nums,
                            skuId: item.productSkuStockId,
                        });
                    });
                    data.skuInfoVOList = arr
                }
                if (this.goodsInfor && this.goodsInfor.length != 0) {
                    let arr = []
                    this.goodsInfor.forEach((item) => {
                        arr.push({
                            goodsId: item.goodsId,
                            num: item.nums,
                            skuId: item.skuId,
                        });
                    });
                    data.skuInfoVOList = arr
                }
                
                if(this.approveFileList.length > 0){
                    data.fileLogList = this.approveFileList.filter((item) => {
                        return delete item.id
                    })
                }
                // console.log(data);
                // this.oderTishi=true
                
                submitOrder(data).then((data) => {
                    // this.inallBtnIcon = false
                    // console.log('submitOrder==',data);
                    if(data.code=='00000'){
                        if(data.data.isSuccess==true){
                            this.$message({
                                message: '订单提交成功',
                                type: 'success'
                            });
                            // this.oderTishi=false
                            this.$router.push({ name: 'MyOrder'})
                            // this.$router.push({ name: 'MyOrder'})
                        }else{
                            this.oderTishi=false
                            if(data.data.failGoodsNameList==null){
                                if(data.data.failType==12){
                                    this.$nextTick(() => {
                                        this.$notify.error({
                                            title: '错误',
                                            message:'禹佳卡清单价格和商品价格不一致',
                                        });
                                    })
                                }else if(data.data.failType==11){
                                    this.$nextTick(() => {
                                        this.$notify.error({
                                            title: '错误',
                                            message:'禹佳卡商品和普通商品无法一起购买',
                                        });
                                    })
                                }else if(data.data.failType==17){
                                    this.$nextTick(() => {
                                        this.$notify.error({
                                            title: '错误',
                                            message:'福利商品和普通商品无法一起购买',
                                        });
                                    })
                                }else if(data.data.failType==18){
                                    this.$nextTick(() => {
                                        this.$notify.error({
                                            title: '错误',
                                            message:'福利商品和禹佳卡商品无法一起购买',
                                        });
                                    })
                                }else if(data.data.failType==9){
                                    this.$nextTick(() => {
                                        this.$notify.error({
                                            title: '错误',
                                            message:data.data.msg,
                                        });
                                    })
                                }else if(data.data.failType==19){
                                    this.$nextTick(() => {
                                        this.$notify.error({
                                            title: '错误',
                                            message:'怡亚通商品不可售',
                                        });
                                    })
                                }else if(data.data.failType==20){
                                    this.$nextTick(() => {
                                        this.$notify.error({
                                            title: '错误',
                                            message:data.data.msg,
                                        });
                                    })
                                }else if(data.data.failType==21){
                                    this.$nextTick(() => {
                                        this.$notify.error({
                                            title: '错误',
                                            message:'地址怡亚通无法识别',
                                        });
                                    })
                                }else{
                                    this.$nextTick(() => {
                                        this.$notify.error({
                                            title: '错误',
                                            message:data.data.msg,
                                        });
                                    })
                                }
                                
                            }else{
                                this.$message.error(data.data.failGoodsNameList[0]+''+data.data.msg);
                                data.data.failGoodsNameList.forEach((item,index)=>{
                                    console.log(item,index)
                                })
                            }
                            
                            
                            // this.$router.push({ name: 'goodsDetail',query:{id: this.goodsId}})
                        }
                        
                    }else if(data.code == 'E1006'){
                        const identity = JSON.parse(localStorage.getItem('identity')).map( item => item.roleId)
                        this.$notify({
                            title: '错误提示',
                            type: 'error',
                            dangerouslyUseHTMLString: true,
                            message: `
                            <div class="notify-msg-box">
                                可用余额不足<br/>
                                ${identity.indexOf(5) != -1 ? `点击<a href="/workbench/myTopUp">去充值</a>` : '' }
                            </div>`,
                            duration: 0
                        });
                    }else if(data.code == 'E1007'){
                        this.$notify({
                            title: '错误',
                            type: 'error',
                            message: `可用授信额度不足`,
                            duration: 5000
                        });
                    }else{
                        data.data.failGoodsNameList.forEach((item)=>{
                            this.$nextTick(() => {
                                this.$notify.error({
                                    title: '错误',
                                    message:"原因:"+data.msg+','+'名称:'+item,
                                });
                            })
                        
                        })
                        
                    }
                    
                }).catch(() => {
                    this.$notify.error({
                        title: '错误',
                        duration: 10000,
                        message:"原因:网络异常，请刷新页面重新提交。(注：如果多次刷新还是无法提交订单，请联系管理员或技术人员。)",
                    });
                }).finally(() => {
                    this.secondaryLoading = false
                })
            }
        },
        goinvoice(){
            this.$router.push({ name: 'AddedQualification'})
        },
        pest(fn){
            return new Promise((resolve)=>{
                setTimeout(()=>{
                    resolve(fn)
                },800)
            })
        },
        allText(){
            this.pest(this.getAddress()).then(()=>{
                return this.pest(this.getInvoice())
            }).then(()=>{
                return this.pest(this.getinfor())
            }).then(()=>{
                return this.pest(this.getplace())
            }).then(()=>{
                this.pest(this.calculateOrder())
            })
        },
        //上传文件
        handleUpload(){
            this.importBtn = true;
            let formData = new FormData();
            formData.append('file', this.uploadfile)
            // console.log(formData)
            // console.log(this.uploadfile)
            importWelfareCard(formData).then((data)=>{
                this.importBtn = false;
                // console.log(data)
                if(data.code == '00000'){
                    this.uploadflie=false
                    this.$message({
                        message: "成功导入数据",
                        type: "success",
                    });
                    this.cardBatchObj = {
                        cardBatchFileName: this.uploadfile.name,
                        cardBatchFileUrl: data.data.fileUrl,
                        cardBatchNo: data.data.batchNo
                    }
                    this.uploadgo=true
                } else {
                    this.$message({
                        type: "error",
                        showClose: true,
                        duration: 0,
                        message: data.desc,
                    })
                }
            })
        },
        // 上传文件前 先将文件存起来
        beforeUpload(file){
            // console.log(file)
            this.uploadfile = file
        },
        //删除文件
        handleRemove () {
            this.uploadfile = undefined;
        },
        handlePictureSuccess() {},
        exportTemplate(url, filename) {
            // console.log(url, filename)
            if(this.exportLoading) return;
            this.exportLoading = true
            this.getBlob(url).then(blob => {
                this.saveAs(blob, filename);
                this.exportLoading = false
            });
        },
        getBlob(url) {
            return new Promise(resolve => {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'blob';
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        resolve(xhr.response);
                    }
                };
                xhr.send();
            });
        },
        saveAs(blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename);
            } else {
                const link = document.createElement('a');
                const body = document.querySelector('body');

                link.href = window.URL.createObjectURL(blob);
                link.download = filename;

                // fix Firefox
                link.style.display = 'none';
                body.appendChild(link);
                
                link.click();
                body.removeChild(link);

                window.URL.revokeObjectURL(link.href);
            }
        },
        getValChange(file,fileList){
            console.log(file,fileList)
            if (fileList.length > 0) {
                this.fileList = [fileList[fileList.length - 1]] 
            }
        },
        handleRemove1(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        beforeRemove(file) {
            return this.$confirm(`确定移除 ${ file.name }？`);
        },
        handleSuccess(file, fileList){
            console.log(file, fileList)
            // if (file.status == "success"  || file.code == "00000") {
            //     let obj={}
            //     obj.uid=fileList.uid
            //     obj.url=file.data
            //     this.fileList.push(obj)
            //     console.log(this.fileList)
            // } else {
            //     this.$message.error(file.desc);
            //     this.handleRemove();
            // }
        },
        getOrderAscription(radios){
            let res ={
                ascriptionType:radios
            }
            getOrderAscription(res).then((data)=>{
                if(data &&data.code=="00000"){
                    this.placeOptions=data.data
                }
            })
        },
        toggleTab(){
            this.getOrderAscription(this.radios)
            this.place=''
        },

        // 审批文件上传
        // 上传文件前
        beforeApproveFile(file){
            console.log(file);
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
 
            const whiteList = ["jpg", "png", "pdf", "JPG", "PNG", "PDF"];
            const size = 10485760;

            if(this.approveFileList.length >= 10){
                this.$message({message:"最多上传10个文件", type: 'error'});
                return false;
            }
            // let aName = this.approveFileList.find(item => item.fileName == file.name)
            // if(aName){
            //     this.$message({message:"重复上传文件名！", type: 'warning'});
            //     return false;
            // }
            if (whiteList.indexOf(fileSuffix) === -1) {
                this.$message({message:"上传文件只能是 jpg,png,pdf格式", type: 'warning'});
                return false;
            } else if(size <= file.size){
                this.$message({message:"上传文件的大小需小于等于10MB", type: 'warning'});
                return false;
            }
            this.upApproveFileList = file
        },
        // 审批文件在线预览
        approvePreview(item){
            // console.log(item);
            let fileName = item.fileName.substring(item.fileName.lastIndexOf(".") + 1);
            // console.log(fileName);
            if(fileName == 'pdf' || fileName == 'PDF'){
                // this.titlePDF = item.fileName
                // let i = item.fileUrl.indexOf('/order')
                // this.srcPDF = '/pdf'+item.fileUrl.slice(i)
                // this.showPDF = true
                window.open(item.fileUrl)
            } else {
                this.showViewer = true
                this.showViewerList = [item.fileUrl]
            }
        },
        // 审批文件删除
        approveDel(item,index){
            console.log(item);
            this.approveFileList.splice(index,1)
            this.$message({message:"删除成功", type: 'success'});
        },
        closeShowViewer(){
            this.showViewer = false
        },
        closeShowPDF(){
            this.showPDF = false
        },
        // 自定义上传
        requestApproveFile(params){
            // console.log(this.upApproveFileList,'up');
            // console.log(params,'up1');

            this.fileLoading = true

            let formData = new FormData()
            formData.append("file", params.file)
            formData.append("type", 1)
            
            uploadApproveFile(formData).then(res => {
                // console.log(res);
                // this.$nextTick(()=>{
                //     console.log(this.$refs.approveUpload,'refsUp');
                // })
                this.fileLoading = false
                if(res.code == '00000'){
                    this.$message({message:"上传成功", type: 'success'});
                    this.approveFileList.push(res.data)
                } else {
                    this.$message({message:"上传失败", type: 'error'});
                }
            })
        },
    },
    
};
</script>

<style lang="scss" scoped>
.secondaryConfirma {
    .secondary-button {
        margin: 15px 0;
        text-align: center;
    }
    .secondary-text {
        color: rgb(39, 38, 38);
        margin-bottom: 15px;
    }
    .secondary-title {
        font-weight: 600;
        font-size: 17px;
        margin: 15px 0;
        color: #000;
    }
    .secondary-content {
        padding: 0 10px 10px;
        box-sizing: border-box;
        border-top: 1px solid rgb(226, 226, 226) !important;
    }
    ::v-deep .el-dialog__body {
        padding: 0 0 !important;
        margin: 0 20px 20px !important;
    }
}
</style>
<style lang="scss">
.bordercd9f49{
    border: 1px solid #cd9f49;
}
.closeBox {
    // margin-top: 50px;
    border: 1px solid #e8e8e8;
    .closeBox_top {
        height: 35px;
        line-height: 35px;
        background: #cd9f49;
        color: #fff;
        padding-left: 15px;
        font-size: 17px;
        margin-bottom: 15px;
    }
    .infor_consignee {
        font-size: 20px;
        margin-right: 40px;
    }
    .infor_remind {
        background: #f2f2f2;
        font-size: 14px;
        padding: 10px 20px;
    }
    .infor_select {
        .infor_text {
            color: #cd9f49;

            .orderAddressBtn {
                cursor: pointer;
            }
        }
        .infor_select-text{
            color: #cd9f49;
        }
        .select-box {
            min-height: 80px;
            .box-infor {
                margin: 15px 0;
                .infor-box {
                    background: rgba(0, 0, 0, 0.03);
                    width: 194px;
                    height: 90px;
                    display: inline-block;
                    cursor: pointer;
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.85);
                    padding: 35px 19px 15px 15px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    .el-radio__input.is-checked .el-radio__inner {
                        border-color: #cd9f49;
                        background: #cd9f49;
                    }
                    .detail_num {
                        color: rgba(0, 0, 0, 0.45);
                        margin-top: 5px;
                        display: -webkit-box;
                        height: 32px;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                }
            }
        }
    }
    .closeBox_infor {
        padding: 20px 20px 10px;
        border-bottom: 2px solid #cd9f49;

        .infor_box {
            margin: 25px 0;
        }
        .infor_name {
            font-size: 14px;
            margin-right: 50px;
        }
        .infor_address {
            font-size: 14px;
            span {
                margin-left: 15px;
            }
        }
        .infor_btn {
            width: 83px;
            height: 27px;
            line-height: 27px;
            color: #fff;
            background: #cd9f49;
            text-align: center;
            margin-left: 10px;
        }
        .infor_new {
            color: #cd9f49;
            cursor: pointer;
        }
    }
    .closeBox_cent {
        padding: 20px 20px;
        .cent_box {
            margin: 10px 0;
            font-size: 14px;
            .el-radio__input.is-checked .el-radio__inner{
                border-color: #cd9f49;
                background: #cd9f49;
            }
            .el-radio__input.is-checked+.el-radio__label{
                color:black
            }
        }
        .cent_text {
            margin-right: 50px;
        }
        .cent_infor {
            margin: 10px 0 10px 30px;
        }
        .cent_btn {
            padding: 2px 10px;
            color: #fff;
            background: #cd9f49;
            text-align: center;
            margin-left: 20px;
        }
        .cent_goods {
            margin: 40px 0;
        }
        .goods_text {
            font-size: 20px;
            margin-bottom: 20px;
        }
        .goods_list{
            font-size: 14px;
        }
        .goods_list>span{
            color: #cd9f49;
            margin-right: 8px;
        }
        .goods_listBtn{
            border: 1px solid #cd9f49;
            padding: 4px 8px;
            margin: 0 8px;
            color: #cd9f49;
            margin-right: 8px;
            font-size: 14px;
            cursor: pointer;
        }
        .boxxlse{
            margin-top: 20px;
            .el-button{
                border: 1px solid #cd9f49;
                color: #cd9f49;
                display: flex;
            }
        }
        .rowBox {
            img {
                width: 60px;
                height: 60px;
                margin-right: 15px;
            }
        }
        .cent_purchase {
            margin-top: 70px;
            border-bottom: 1px solid #d7d7d7;
            padding-bottom: 25px;
        }
        .purchase {
            font-size: 14px;
            color: #333;
            padding-bottom: 15px;
        }
        .purchase_text {
            font-size: 12px;
            color: #aeaeae;
            margin-left: 20px;
        }
        .cent_how {
            margin: 20px 0;
        }
        .freight {
            margin-top: 10px;
        }
        .cent-remind {
            font-size: 14px;
            border: 1px solid #cd9f49;
            padding: 10px 15px;
        }
        .remind_text {
            margin-bottom: 10px;
        }
        .cent_inall {
            margin: 20px 0;
            font-size: 14px;
            span {
                color: red;
                font-size: 15px;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .inall_btn {
                margin-top: 15px;
                width: 198px;
                height: 62px;
                line-height: 62px;
                text-align: center;
                background: #cd9f49;
                color: #fff;
                border-radius: 10px;
                font-size: 18px;
                cursor: pointer;
            }
        }
    }
    .el-form-item {
        .el-form-item__label {
            width: 80px;
        }
        .el-button {
            width: 70px;
            text-align: center;
            padding: 0;
        }
        .el-cascader {
            width: 100%;
        }
    }
    .custom{
        .el-dialog__header{
            padding: 0;
        }
    }
}
#dialogVisibleApproveBox {
    width: 100%;
    height: auto;
    position: relative;

    .approveFileList {
        width: 100%;
        height: auto;
        margin-top: 10px;
        margin-bottom: 15px;
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width : 7px;
            height: 1px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 7px;
            box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
            background   : #808080;
        }
        &::-webkit-scrollbar-track {
            box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 7px;
            background   : #ededed;
        }

        .fileList {
            margin-bottom: 15px;

            .fileBtn {
                width: 32%;
                color: #CD9F49;

                span {
                    cursor: pointer;
                    margin: 0 10px;
                }
            }
        }
    }
}
</style>