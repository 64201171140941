<template>
    <div class="pdf-mask">
        <div class="close-btn" @click.stop="closeBtn()">×</div>

        <div class="pdf-box">
            <div class="pdf-head">
                <p class="title">{{title}}</p>
                <p class="arrow">
                    <!-- 上一页 -->
                    <span @click="changePdfPage(0)" class="turn" :class="{grey: currentPage==1}">上一页</span>
                    {{currentPage}} / {{pageCount}}
                    <!-- 下一页 -->
                    <span @click="changePdfPage(1)" class="turn" :class="{grey: currentPage==pageCount}">下一页</span>
                </p>
            </div>
            
            <pdf
                :src="src"
                :page="currentPage"
                @num-pages="pageCount=$event"
                @page-loaded="currentPage=$event"
                @loaded="loadPdfHandler">
            </pdf>
        </div>
        
    </div>
</template>

<script>   // 引入PDF
import pdf from 'vue-pdf'
export default {
    components: {pdf},
    props: {
        url: { // pdf文件地址
            type: String,
            required: true
        },
        title: { // pdf文件标题
            type: String,
            default: 'PDF 预览'
        },
    },
    data () {
        return {
            currentPage: 0, // pdf文件页码
            pageCount: 0, // pdf文件总页数
            fileType: 'pdf', // 文件类型
            src: '',
            // src: 'http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf', // 文件url
        }
    },
    watch: {
        biaoti: {
            immediate: true,
            deep: true,
            handler(n, o) {
                console.log(n, o)
            }
        }
    },
    created(){
        // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
        this.$nextTick(()=>{
            this.src = pdf.createLoadingTask(this.url)
            console.log(this.url);
            console.log(this.title);
        })
    },
    methods: {
        // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
        changePdfPage(val) {
            // console.log(val)
            if (val === 0 && this.currentPage > 1) {
                this.currentPage--
                // console.log(this.currentPage)
            }
            if (val === 1 && this.currentPage < this.pageCount) {
                this.currentPage++
                // console.log(this.currentPage)
            }
        },

        // pdf加载时
        loadPdfHandler() {
            this.currentPage = 1 // 加载的时候先加载第一页
        },

        // 关闭
        closeBtn(){
            this.$emit("closeShowPDF")
        }

    }
}

</script>

<style lang="scss" scoped>
.pdf-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #00000070;
    overflow-y: auto;
    z-index: 5000;

    .close-btn {
        position: absolute;
        top: 10px;
        right: 20px;
        z-index: 10;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 25px;
        color: #fff;
        font-size: 30px;
        background: #e5e5e536;
        border-radius: 50%;
        cursor: pointer;
    }

    .pdf-box {
        width: 700px;
        margin: 0 auto;
        padding: 10px;

        .pdf-head {
            display: flex;
            justify-content: space-between;

            .title {
                width: 60%;
                color: #fff;
                padding: 0 15px 0 0;
                box-sizing: border-box;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .arrow {
                width: 40%;
                text-align: right;
                margin-bottom: 10px;
                color: #fff;

                .turn {
                    margin: 0 8px;
                    cursor: pointer;

                    &:hover {
                        color: #CD9F49;
                    }
                }
            }
        }

    }

    
}
</style>